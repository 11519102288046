(function ($) {
  var changeFBPagePlugin = function () {
    var container_width = (Number($('.fb-feed-content').width())).toFixed(0);
    var container_height = (Number($('.fb-feed-content').height())).toFixed(0);

    if (!isNaN(container_width) && !isNaN(container_height)) {
      $(".fb-page").attr("data-width", container_width).attr("data-height", container_height);
    }

    if (typeof FB !== 'undefined') {
      FB.XFBML.parse();
    }
  };

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                /**
                 * Sticky menu on scroll
                 */
                var $body   = $('body'),
                    $banner = $('.banner');
                var stickyClass = 'sticky-navbar';
                var headerPosition = $banner.height() - 60;

                $(window).on('orientationchange', function (event) {
                    headerPosition = $banner.height() - 60;
                });

                $(window).on('resize', function (event) {
                    headerPosition = $banner.height() - 60;
                });

                $(window).on('scroll', function () {
                    if ($(this).scrollTop() >= headerPosition) {
                        $body.addClass(stickyClass);
                    } else {
                        $body.removeClass(stickyClass);
                    }
                });

                /**
                 * Mega menu
                 */
                $(document).on('click', '.yamm .dropdown-menu', function (e) {
                    e.stopPropagation();
                });

                /**
                 * Smooth scroll on mega menu
                 */
                $('.dropdown-toggle, .search__toggle').on('click', function (e) {
                    e.preventDefault();

                    $('html, body').stop().animate({
                        'scrollTop': $(this).offset().top
                    }, 600, 'swing');
                });

                /**
                 * Search form
                 */
                var $searchForm  = $('.search__form'),
                    $searchInput = $('.search-field');

                $('.search__toggle').click(function (e) {
                    e.preventDefault();

                    $searchForm.toggleClass('open');

                    if ($searchForm.hasClass('open')) {
                        $searchForm.fadeIn();
                        $searchInput.select().focus();
                    }
                    else {
                        $searchForm.fadeOut();
                        $searchInput.blur();
                    }
                });

                $('.primary-nav .dropdown-toggle').click(function () {
                    $searchForm.removeClass('open').fadeOut();
                });

                $(document).keydown(function (e) {
                    if ($searchForm.hasClass('open')) {
                        if (e.which === 27) {
                            $searchInput.blur();
                            $searchForm.removeClass('open').fadeOut();
                        }
                        else if (e.which === 13) {
                            e.preventDefault();

                            if ($searchInput.val().length > 2) {
                                $searchForm.find('form').submit();
                            }
                        }
                    }
                });

                $('.search__toggle--mobile').click(function (e) {
                    e.preventDefault();

                    $('.search__form--mobile').fadeToggle();
                });

                // ADD SLIDEDOWN/UP ANIMATION TO DROPDOWN //
                $('.dropdown')
                    .on('show.bs.dropdown', function (e) {
                        $(this).find('.dropdown-menu').first().stop(true, true).fadeIn();
                    })
                    .on('hide.bs.dropdown', function (e) {
                        $(this).find('.dropdown-menu').first().stop(true, true).fadeOut();
                    });

                /**
                 * Mobile menu toggle
                 */
                var $mobileMenu = $('.mobile--menu');
                var $mobileNavbar = $('.mobile-navbar');

                $('.menu__toggle, .menu__text').click(function () {
                    $mobileNavbar.toggleClass('open');

                    if ($mobileNavbar.hasClass('open')) {
                        $mobileMenu.fadeIn();
                    }
                    else {
                        $mobileMenu.fadeOut();
                    }
                });

                /**
                 * Accordion mobile menu
                 */
                function accordion($el) {
                    // $el.removeAttr('href');
                    var element = $el.parent('li');

                    if (element.hasClass('open')) {
                        element.removeClass('open');
                        element.find('> ul').slideUp();
                    }
                    else {
                        element.addClass('open');
                        element.find('> ul').slideDown();
                        element.siblings('li').removeClass('open');
                        element.siblings('li').find('> ul').slideUp();
                    }
                }

                $('.sidebar__menu')
                    .find('> li.menu-item-has-children > a')
                    .removeAttr('href')
                    .on('click', function () {
                        accordion($(this));
                    });

                $('.sidebar__menu').find('.menu__collapse').remove();

                $mobileMenu
                    .find('> ul > li.menu-item-has-children > a')
                    .removeAttr('href')
                    .on('click', function () {
                        accordion($(this));
                    });

                $mobileMenu
                    .find('> ul > li.menu-item-has-children > ul > li.menu-item-has-children > a')
                    .removeAttr('href')
                    .on('click', function () {
                        accordion($(this));
                    });

                $mobileMenu
                    .find('> ul > li.menu-item-has-children > ul > li.menu-item-has-children > ul .menu__collapse').remove();

                $mobileMenu.find('>ul>li.has-sub>a').append('<span class="holder"></span>');
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                $('.block[data-function="image"]')
                    .css('cursor', 'pointer')
                    .click(function () {
                        location.href = $('.block[data-function="text"][data-position="' + $(this).data('position') + '"]').find('.block__link').attr("href");
                    });

                $('#ticker').webTicker();
            },
            finalize: function () {
                // Slick slider initialisation
                $('.slider').slick({
                        arrows: false,
                        autoplay: true,
                        autoplaySpeed: 5000,
                        dots: true,
                        fade: true,
                        infinite: true,
                        pauseOnDotsHover: true,
                        pauseOnHover: false,
                        speed: 1000
                    });

              $(window).on('resize', function () {
                setTimeout(function () {
                  changeFBPagePlugin();
                }, 500);
              });

              changeFBPagePlugin();
            }
        },
        'page_template_template_magasin': {
            init: function () {
                $(".shop__images").lightGallery({
                    selector: '.shop__image'
                });
            }
        },
        'page_template_template_histoire': {
            init: function () {
                $('.event__body').addClass('invisible');
            },
            finalize: function () {
                var $event = $('.event');
                // var height = $event.height() + 35;
                var height = "40%";

                $event.viewportChecker({
                    classToAdd: 'filled',
                    offset: height
                });

                $('.event:nth-of-type(odd) .event__body').viewportChecker({
                    classToAdd: 'visible animated fadeInRight',
                    offset: height
                });

                $('.event:nth-of-type(even) .event__body').viewportChecker({
                    classToAdd: 'visible animated fadeInLeft',
                    offset: height
                });

                $('a[href*="#"]:not([href="#"])').click(function () {
                    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                        var target = $(this.hash);
                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        if (target.length) {
                            $('html, body').animate({
                                scrollTop: target.offset().top
                            }, 500);
                            return false;
                        }
                    }
                });
            }

        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function setGoogleMap() {
    var isDraggable = jQuery(document).width() > 480;
    var myLatlng = new google.maps.LatLng(46.60377, 6.23465);
    var mapCanvas = document.getElementById('map-canvas');
    var mapOptions = {
        center: myLatlng,
        zoom: 15,
        draggable: isDraggable,
        scrollwheel: false,
        streetViewControl: false,
        mapTypeControl: false,
        panControl: !isDraggable,
        panControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM
        },
        zoomControlOptions: {
            style: google.maps.ZoomControlStyle.LARGE,
            position: google.maps.ControlPosition.LEFT_CENTER
        },
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [{
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [{"color": "#444444"}]
        }, {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [{"color": "#f2f2f2"}]
        }, {
            "featureType": "landscape",
            "elementType": "labels.text",
            "stylers": [{"visibility": "on"}, {"hue": "#ff0000"}]
        }, {"featureType": "poi", "elementType": "all", "stylers": [{"visibility": "off"}]}, {
            "featureType": "road",
            "elementType": "all",
            "stylers": [{"saturation": -100}, {"lightness": 45}]
        }, {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [{"visibility": "simplified"}]
        }, {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [{"visibility": "off"}]
        }, {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [{"visibility": "off"}]
        }, {"featureType": "water", "elementType": "all", "stylers": [{"color": "#10ade4"}, {"visibility": "on"}]}]
    };
    var image = {
        url: SiteParameters.theme_directory + '/dist/images/marker.png',
        // url: '/wp-content/themes/sevj/dist/images/marker.png',
        scaledSize: new google.maps.Size(27, 36),
        anchor: new google.maps.Point(0, 36)
    };
    // var image = '/wp-content/themes/sevj/dist/images/marker.png';
    var map = new google.maps.Map(mapCanvas, mapOptions);
    var marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        title: "Pmbcom: Stratégie & Contenu créatif",
        icon: image,
        animation: google.maps.Animation.DROP
    });
    marker.setMap(map);

    return true;
}
